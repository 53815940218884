.bgArcopime {
  background-color: #1D70B6;
  color: #FFF;
}
.btnCerrarModal {
  position: absolute;
  top: 3px;
  right: 6px;
}
.rdt_TableCell {
  padding-left: 0px !important;
  padding-right: 0px !important;
  font-size: 15px !important;
}
div.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 4px 19px 4px !important;
}
.titleModalSweet {
  font-size: 20px;
}
.tagLabel {
  font-weight: 600;
}
input,
select {
  box-shadow: 1px 2px 9px -6px #000;
}
.bg-orange {
  background-color: #E64A19;
}
.contHtmlModalReingreso p {
  text-align: initial;
  font-size: 14px;
  margin-bottom: 8px;
}
.contHtmlModalReingreso p {
  margin-top: 4px;
}
.btn-arcoprime-st {
  background: #29B6F6;
  width: 150px;
  color: #fff;
  font-weight: 600;
}
div.rdt_TableHead {
  background: #29B6F6;
}
.btn-arcoprime-st:hover {
  color: #05233b !important;
}
.btnCerrarSesion:hover::after {
  color: #07477a;
}
div.card-header {
  border-bottom: 4px solid #07477a;
}
.logoBlanco {
  background-image: url('../assets/lab.png');
  width: 120px;
  height: 51px;
  background-size: cover;
}
.nav-link {
  font-weight: 700;
  color: #07477a !important;
}
.rdt_TableCol {
  justify-content: center;
}
.roundedLeft {
  border-radius: 5px 0 0 5px !important;
}
.roundedRight {
  border-radius: 0 5px 5px 0 !important;
}
.borderBlanco {
  border: 1px solid transparent;
}
.imgPerfil {
  border-radius: 8px 0 0 8px;
}
.dropdown-toggle::after {
  padding-top: -17px;
  border-top: 0.7em solid;
  border-right: 0.6em solid transparent;
  border-left: 0.6em solid transparent;
}
.btnRRHH {
  background-color: #47b48a;
}
.btnAdmin {
  background-color: #007cbe;
}
.btnSweetModal {
  height: 48px;
  width: 150px;
  border-radius: 38px;
  border-width: 0px;
  color: #FFF;
  font-weight: 500;
  font-size: 16px;
}
.containerMensajeSimple {
  overflow-y: overlay !important;
  width: 100vw !important;
}
.titleMensajeSimple {
  font-weight: 500;
  color: #003965 !important;
  font-size: 18px !important;
}
.popUpMensajeSimple {
  border-radius: 22px;
  width: 390px !important;
}
.progressBarMensajeSimple {
  background-color: #63b0ebb3;
}
.popUpSweetCancelarEnviar {
  width: 500px;
  border-radius: 22px !important;
  font-weight: 900 !important;
}
.btnCopec {
  margin-left: 15px;
  height: 48px;
  padding-right: 0px;
  width: 150px;
  border-radius: 38px;
  border-width: 0px;
  color: #FFF;
  background-color: #4785b4;
  font-weight: 500;
  font-size: 16px;
}
.btnExcel {
  height: 48px;
  width: 150px;
  border-radius: 38px;
  border-width: 0px;
  color: #FFF;
  background-color: #209b76;
  font-weight: 500;
  font-size: 16px;
}
.btnArcoprime {
  height: 48px;
  width: 150px;
  border-radius: 38px;
  border-width: 0px;
  color: #FFF;
  background-color: #4785b4;
  font-weight: 500;
  font-size: 16px;
}
.titleModalCargando {
  font-weight: 500;
  font-size: 23px !important;
}
.btnSweetSolo {
  height: 48px;
  padding: 0px;
  width: 150px;
  border-radius: 38px;
  border-width: 0px;
  color: #FFF;
  background-color: #4785b4;
  font-weight: 500;
  font-size: 16px;
}
.btnAtras {
  margin-right: 15px;
  align-self: center;
  height: 48px;
  width: 150px;
  border-radius: 38px;
  border-width: 0px;
  background: #b13333;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}
.candadoPosicion {
  display: inline-flex;
  justify-content: space-between;
}
.cardContrato {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.285);
  border-radius: 0.25rem;
}
.contenedorCardsModal {
  height: 350px;
  overflow-y: scroll;
  padding: 8px 8px 2px 8px !important;
  border: 1px solid #00000049;
  border-radius: 5px;
  background: #f7f7f7;
}
.contenedorCardsModal::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
  outline: 0px;
  border-radius: 2px;
}
.contenedorCardsModal::-webkit-scrollbar-track {
  box-shadow: rgb(255, 255, 255);
}
.contenedorCardsModal::-webkit-scrollbar {
  width: 8px;
  margin-left: 30px;
  border-radius: 2px;
  background-color: #CCD7E0 !important;
}
body::-webkit-scrollbar-thumb,
div.irrHai::-webkit-scrollbar-thumb,
pre::-webkit-scrollbar-thumb,
div.table-responsive::-webkit-scrollbar-thumb,
.contenedorTabla > div::-webkit-scrollbar-thumb,
.gelpCx::-webkit-scrollbar-thumb,
.inputMotivo::-webkit-scrollbar-thumb {
  background-color: #1D70B6;
  outline: 0px;
  border-radius: 2px;
}

body::-webkit-scrollbar-track,
div.irrHai::-webkit-scrollbar-track,
pre::-webkit-scrollbar-track,
div.table-responsive::-webkit-scrollbar-track,
.contenedorTabla > div::-webkit-scrollbar-track,
.gelpCx::-webkit-scrollbar-track,
.inputMotivo::-webkit-scrollbar-track {
  box-shadow: rgb(255, 255, 255);
}

div.irrHai::-webkit-scrollbar,
pre::-webkit-scrollbar,
div.table-responsive::-webkit-scrollbar,
.contenedorTabla > div::-webkit-scrollbar, 
.gelpCx::-webkit-scrollbar {
  height: 8px;
  margin-left: 30px;
  border-radius: 2px;
  background-color: #CCD7E0 !important;
}
pre::-webkit-scrollbar,
body::-webkit-scrollbar,
.contenedorTabla > div::-webkit-scrollbar, 
.inputMotivo::-webkit-scrollbar {
  width: 8px;
  margin-left: 30px;
  border-radius: 2px;
  background-color: #CCD7E0 !important;
}
#itemBar, #itemBar2 {
  border-radius: 8px;
}
.inputMotivo {
  margin-left: 28px;
  margin-right: 28px;
  font-size: 16px;
  max-height: 100px;
  padding-left: 6px;
}
.modal-content {
  border-radius: 22px !important;
}
.headTablaSolicitud th.text-center {
  white-space: nowrap;
  vertical-align: middle;
}
.cuerpoTablaDetSolicitud td.tdTable {
  vertical-align: middle!important;
  padding: 3px;
}
.cuerpoTablaDetSolicitud tr:hover {
  background: rgb(240, 240, 240);
}


code {
  text-align: start;
  display: flex;
  max-height: 76vh;
  white-space: break-spaces!important;
}
pre {
  margin-bottom: 0px;
  font-size: 15px;
}
.accordion-button:focus {
  box-shadow: none;
}
.cardPestania {
  border-width: 0px 1px 1px 1px;
  border-color: #d3d3d3;
  border-style: solid;
  border-radius: 0px;
}
.rdt_TableCol div {
  overflow: visible!important;
  white-space: normal;
  text-align: center;
}
.contenedorTabla {
  padding: 0.2rem 0.2rem;
}
.btnAccion {
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 3px;
  border-radius: 6px;
}
@media(max-width:600px) {
  .btnCerrarSesion:hover {
    background-color: transparent;
    border-radius: 30px !important;
    color: rgb(39, 39, 39);
  }
}